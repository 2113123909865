import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout/Layout"
import SEO from "layout/SEO"
import Container from "layout/Container"
import { modal } from "../context/AppReducer"
import { AppContext } from "../context/AppContext"

export default () => {
  const { state, dispatch } = useContext(AppContext)
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/smsrx1-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  useEffect(() => {
    dispatch({ type: modal.HIDE_MODAL })
  }, [dispatch])

  const complete = data.complete.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="ePrescription Sent" />
      <Container isCentered>
        <center>
          <h1 className="title is-size-3 my-2">ePrescription Sent</h1>
          <Container isCentered mobile={8} tablet={6} desktop={4}>
            <Img fluid={complete} alt="Success!" />
          </Container>
          <p className="pb-1">
            You have sent an ePrescription to {state?.patient?.firstName}{" "}
            {state?.patient?.lastName}.
          </p>
          <Link to="/patients/view" className="button is-primary is-medium">
            Back to Patient Profile
          </Link>
        </center>
      </Container>
    </Layout>
  )
}
